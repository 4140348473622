import React, { useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";
import Countries from "components/Countries";
import Partenaires from "components/Partenaires";
import { slugify, removeSpaces } from "utils/index";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { StaticImage } from "gatsby-plugin-image";
import { LazyLoadImage } from "react-lazy-load-image-component";

/* Markdown Converter */
const showdown = require("showdown");
const converter = new showdown.Converter();

const BlogPages = ({ pageContext: content }) => {
  const {
    _companies,
    _countries,
    _others,
    _otherCountries,
    _staticTexts,
    _topMenus,
    _companyMenus,
    _lang,
    _blogs,
  } = content;

  return (
    <Layout
      menuContent={{
        companies: _companies,
        countries: _countries,
        others: _others,
        otherCountries: _otherCountries,
        topMenus: _topMenus,
        companyMenus: _companyMenus,
        blogs: _blogs,
      }}
      menuSection={content.menuSection}
      menuTitle={content.menuTitle}
      slug={content.slug}
      ratingValue={content.ratingValue || 4.8}
      ratingCount={content.ratingCount || 165}
      staticTexts={_staticTexts}
      lang={_lang}
    >
      <Helmet>
        <title>{content.pageTitle}</title>
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={content.metaKeywords} />
        <meta name="description" content={content.metaDescription} />
      </Helmet>

      <div className="card-primary page-width blog-content">
        <h1>{content.pageTitle}</h1>

        <div
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(content.body) }}
        ></div>
      </div>
    </Layout>
  );
};

export default BlogPages;
